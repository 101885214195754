<template>
  <div>
    <div id="box">
      <div id="from">
        <h2>书云心理健康患者评估系统</h2>
        <el-form
          :model="user"
          status-icon
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="患者姓名">
            <el-input
              placeholder="请输入评估人的姓名"
              v-model="user.Cname"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证">
            <el-input
              oninput="if(value.length > 18)value = value.slice(0, 18)"
              placeholder="请输入评估人的身份证号"
              v-model="user.CID"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="就诊卡号">
            <el-input
              oninput="if(value.length > 18)value = value.slice(0, 18)"
              placeholder="请输入评估人的就诊卡号"
              v-model="user.HospitalID"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="login">验证</el-button>
            <el-button type="warning" @click="resetForm">重置</el-button>
            <el-button type="success" @click="toSystem">退出</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div id="footer">版权为书云虚实科技有限公司所有</div>
  </div>
</template>

<script>
import { Message } from "element-ui";
import api from "../../http/api";
export default {
  data() {
    return {
      user: {
        Cname: "",
        CID: "",
        HospitalID: "",
      },
    };
  },
  methods: {
    async login() {
      const data = await api.login.PatientLogin(this.user);
      // console.log("患者登录", data);
      if (data.data.data == null) {
        Message.error(
          "该患者不存在或信息错误，请医生前往患者管理进行添加，或核对后输入"
        );
      }
      if (data.data.message == "服务器连接失败！") {
        Message.error("服务器响应过慢，请等待或刷新页面");
      }
      if (data.data.data !== null) {
        const patientN = data.data.data.cname;
        const patientI = data.data.data.cid;
        localStorage.setItem("patientN", JSON.stringify(patientN));
        localStorage.setItem("patientI", JSON.stringify(patientI));
        Message.success("信息验证成功");
        this.$router.push("/Assessment");
      }
    },
    // 重置数据
    resetForm() {
      this.user = {};
    },
    toSystem() {
      this.$router.push("/system");
    },
  },
};
</script>

<style lang="scss" scoped>
#box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgb(201, 180, 180);
}
#from {
  width: 400px;
  // height: 250px;
  padding: 20px;
  text-align: center;
  background-color: white;
  border-radius: 15px;
  h2 {
    margin: 20px;
    font-size: 24px;
  }
  .zh {
    margin-bottom: 25px;
  }
  button {
    margin-top: 25px;
  }
}
.el-form {
  width: 400px;
  margin: auto;
}
#footer {
  margin-bottom: 100px;
  height: 50px;
  line-height: 50px;
  background: rgb(201, 180, 180);
}
</style>
